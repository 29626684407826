var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('section',[_c('div',{staticClass:"ma-5 d-flex align-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-arrow-left")])],1),_c('div',{staticClass:"text-h4 primary--text font-weight-bold ml-3"},[_vm._v(_vm._s(_vm.vouchers.code))])],1),_c('v-row',{staticClass:"my-5"},[_c('v-col',{attrs:{"cols":"12","xl":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 black--text font-weight-bold ma-5"},[_vm._v("Users")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.vouchers.enrollees,"items-per-page":5},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{},[(!item.student.image)?_c('v-avatar',{attrs:{"size":"35","color":"primary-2"}}):_c('v-avatar',{attrs:{"size":"35","color":"secondary"}},[_c('v-img',{attrs:{"src":((item.student.aws) + "/users/" + (item.student.uuid) + "/image/" + (item.student.image.image))}})],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(((item.student.firstname) + " " + (item.student.lastname)))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(("" + (item.student.email)))+" ")])]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_c('v-select',{attrs:{"solo":"","dense":"","items":_vm.category,"hide-details":"","item-text":"name","item-value":"id","readonly":"","flat":""},model:{value:(item.student.level),callback:function ($$v) {_vm.$set(item.student, "level", $$v)},expression:"item.student.level"}})],1)]}}],null,true)}),_c('div',{staticClass:"text-h6 black--text font-weight-bold ma-5"},[_vm._v("Courses")]),_c('v-row',_vm._l((_vm.vouchers.courses),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","lg":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[(item.image)?_c('v-card',{attrs:{"flat":""}},[_c('v-img',{attrs:{"aspect-ratio":"1.4","contain":"","src":((item.aws) + "/courses/" + (item.uuid) + "/image/" + (item.image.image))}})],1):_c('v-avatar',{attrs:{"tile":"","color":"success"}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 font-weight-bold"},[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',{staticClass:"body-1"},[_vm._v(_vm._s(item.teacher.email))])],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1):_c('div',{staticClass:"body-1"},[_vm._v("Loading... ")])}
var staticRenderFns = []

export { render, staticRenderFns }