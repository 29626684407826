<template>
  <section v-if="!loading">
    <div class="ma-5 d-flex align-center">
      <v-btn icon @click="$router.back()">
        <v-icon size="36" >mdi-arrow-left</v-icon>
       
      </v-btn>
      <div class="text-h4 primary--text font-weight-bold ml-3">{{vouchers.code}}</div>
    </div>
    <v-row class="my-5" >
      <v-col cols="12" xl="12">
        <v-row>
          <!-- <v-col cols="12" lg="6">
            <v-card>
              <v-sheet class="success d-flex justify-space-between align-center" style="height: 50px">
                <div class="body-2 white--text ml-5">COURSES</div>
                <div class="body-2 white--text mr-5">{{vouchers.courses.length}}</div>
              </v-sheet>
              <v-list style="max-height: 600px; overflow: auto">
                <v-list-item v-for="item in vouchers.courses" :key="item.id">
                  <v-list-item-avatar tile>
                    <v-img v-if="item.image" :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"></v-img>
                    <v-avatar v-else tile color="success"></v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.teacher.email }}</v-list-item-subtitle>

                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col> -->
          <v-col cols="12">
            <div class="text-h6 black--text font-weight-bold ma-5">Users</div>

            <v-data-table
              :headers="headers"
              :items="vouchers.enrollees"
              :items-per-page="5"
            >
              <template v-slot:[`item.image`]="{ item }">
                <div class="">
                  <v-avatar v-if="!item.student.image" size="35" color="primary-2"/>
                  <v-avatar v-else size="35" color="secondary">
                    <v-img :src="`${item.student.aws}/users/${item.student.uuid}/image/${item.student.image.image}`"></v-img>
                  </v-avatar>
                </div>
                
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="">
                  {{`${item.student.firstname} ${item.student.lastname}`}}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div class="">
                  {{`${item.student.email}`}}
                </div>
              </template>
              <template v-slot:[`item.level`]="{ item }">
                <div class="">
                  <v-select 
                  v-model="item.student.level"
                  solo 
                  dense 
                  :items="category"
                  hide-details
                  item-text="name"
                  item-value="id"
                  readonly
                  flat
                  />
                </div>
              </template>
            </v-data-table>
            <div class="text-h6 black--text font-weight-bold ma-5">Courses</div>
            <v-row>
              <v-col cols="12" lg="3" v-for="item in vouchers.courses" :key="item.id">
                <v-card outlined>
                  <v-card-text>
                    <v-card flat v-if="item.image">
                      <v-img 
                      aspect-ratio="1.4"
                      contain 
                      :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"></v-img>
                    </v-card>
                    <v-avatar v-else tile color="success"></v-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 font-weight-bold">{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{ item.teacher.email }}</v-list-item-subtitle>

                    </v-list-item-content>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
  </section> 
  <div class="body-1" v-else>Loading... </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
var CryptoJS = require("crypto-js");

export default {
  data: () => ({
    loading: true,
    headers:[
      { text: "", align: "start", value: "image",},
      { text: "NAME", value: "name" },
      { text: "EMAIL", value: "email" },
      { text: "LEVEL", value: "level" },
    ]
  }), 
  computed: {
    ...mapState('admin', {
      vouchers: (state) => state.vouchers,
      category: (state) => state.user_category,
    })
  },
  mounted() {
    let key = CryptoJS.AES.decrypt(this.$route.params.id, 'secret')
    this.getUserCategory()
    console.log(key.toString(CryptoJS.enc.Utf8));
    this.showVoucherDetails(key.toString(CryptoJS.enc.Utf8))
    .then(() => {
      this.loading = false
    })
    
  },
  methods: {
    ...mapActions('admin', ['showVoucherDetails', 'getUserCategory'])
  }
}
</script>